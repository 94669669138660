<template>
  <hzba-base-modal
    :title="fragenblock && fragenblock.getTitle()"
    :open="open"
    :ask-before-close="false"
    :go-back-mode="true"
    @close="() => cancel()"
  >
    <!--    :ask-before-close="hm.form.isFormDirty()"-->
    <!--    @close="() => hm.cancel()"-->
    <!--    @save="() => hm.save()"-->

    <ion-content>
      <section-wrapper
        v-if="fragenblock?.path"
        class="mt-4 mx-6 text-xs"
        style="color: #7f7f7f"
      >
        {{ getNestedBreadcrumbTitle(fragenblock.path) }}
      </section-wrapper>
      <section-wrapper>
        <RecursiveForm
          v-if="!surveyMapModal && fragenblock"
          :parent-fragenblock="fragenblock"
          :modal-depth="modalDepth"
        />
        <HzbaForm
          v-if="surveyMapModal"
          :form-frages="fragenblock?.frages"
        />

        <div>
          <div v-if="surveyMapModal">
            <SurveyMapFragenblockButtons
              @delete="deleteInstance()"
            />
          </div>
          <div v-else>
            <hzba-delete-duplicate-item
              v-if="fragenblock && fragenblock.blockTyp && fragenblock.blockTyp.toLowerCase() === 'mehrfach'"
              :fragenblock="fragenblock"
              @delete="deleteInstance()"
              @duplicate="duplicateInstance()"
            />
          </div>
        </div>
      </section-wrapper>
    </ion-content>
  </hzba-base-modal>
</template>


<script lang="ts">

import HzbaBaseModal from "@/components/Base/BaseModal";
import HzbaGroup from "@/components/hzba/Base/HzbaGroup";
import HzbaBaseInput from "@/components/hzba/Base/Input/HzbaBaseInput";
import { openHzbaModal } from "@/utilities/modal-helper";
import { computed, defineComponent, PropType } from "vue";
import { useI18n } from 'vue-i18n';

import SectionWrapper from "@/components/Base/SectionWrapper.vue";
import HzbaForm from "@/components/hzba/Base/HzbaForm.vue";
import HzbaDeleteDuplicateItem from "@/components/hzba/Base/Input/HzbaDeleteDuplicateItem.vue";
import HzbaModalItemBlock from "@/components/hzba/Base/Input/HzbaModalItemBlock.vue";
import RecursiveForm from "@/components/hzba/RecursiveForm.vue";
import SurveyMapFragenblockButtons from '@/components/v2/Map/SurveyMapFragenblockButtons.vue';
import {
getNestedBreadcrumbTitles,
useIdentifierFragenblockFinder
} from "@/composables/Bestandsaufnahme/useIdentifierFinder";
import { Fragenblock } from "@/models/ba/Fragenblock";
import { store } from "@/store/store";
import { IonContent, modalController } from "@ionic/vue";

export default defineComponent({
  name: "FragenblockModal",
  components: {
    RecursiveForm,
    HzbaDeleteDuplicateItem,
    SurveyMapFragenblockButtons,
    HzbaModalItemBlock,
    HzbaForm,
    HzbaBaseInput, HzbaGroup, HzbaBaseModal, IonContent, SectionWrapper },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    // ba: {
    //   type: Object as PropType<Heizungsbestandsaufnahme>,
    //   required: true,
    // },
    fragenblockBase: {
      type: Object as PropType<Fragenblock>,
      required: true,
    },
    modalDepth: {
      type: Number,
      default: 0,
    },
    surveyMapModal: {
      type: Boolean,
      required: false
    }
  },
  emits: ["close"],
  setup(props: any) {
    console.log("setup fragenblock modal")
    const { t } = useI18n({ useScope: 'global' });

    const currentBa = computed(() => {
      return store.state.currentHzba.currentBa;
    })

    const fragenblock = computed<Fragenblock | undefined>(() => {
       return currentBa.value && useIdentifierFragenblockFinder(currentBa.value, props.fragenblockBase.path, undefined);
    })


    // const hm = new GenericBAModal(props.fragenBlock)

    function getFrages(fragenBlock: Fragenblock) {
      return fragenBlock.frages;
    }

    async function cancel() {
      await modalController.dismiss();
    }

    // async function save() {
    //   console.log("Saving Fragenblock Modal...")
    //   // logger.info()
    //   // console.log("TEST?!", fragenblock.value, fragenblock.value?.toJson());

    //   const valid = fragenblock.value?.isFormValid();
    //   const dirty = fragenblock.value?.isFormDirty();


    //   if (valid) {
    //     fragenblock.value?.submit();
    //     // console.log("fragenblock submit save", fragenblock.value)

    //     await modalController.dismiss({
    //       action: 'SAVE',
    //       data: await fragenblock.value?.copyJson()
    //     });

    //     store.commit( 'currentHzba/removeModalBa')
    //   } else {
    //     fragenblock.value?.setShowErrors();
    //   }

    //   console.log("SAVE...")
    //   console.log("valid..", valid)
    //   console.log("dirty..", dirty)
    // }

    async function deleteInstance() {
      const copiedFragenblock = await fragenblock.value?.toClassJson();

      await modalController.dismiss({
        action: 'DELETE',
        data: copiedFragenblock,
      })
    }

    async function saveInstance() {
      const copiedFragenblock = await fragenblock.value?.toClassJson();

      await modalController.dismiss({
        action: 'SAVE',
        data: copiedFragenblock,
      })
    }

    async function duplicateInstance() {
      const copiedFragenblock = await fragenblock.value?.toClassJson();

      await modalController.dismiss({
        action: 'DUPLICATE',
        data: copiedFragenblock,
      })
    }


    // const setDataFromCallback = (parentFragenblock: any, index: number, i: number, data: any) => {
    //   console.log("setDataFromCallback", fragenBlock, index, i);
    //   console.log("setDataFromCallback - data", data);
    //   const parent = fragenBlock.value!.fragenBlocks![index];
    //   fragenBlock.value!.fragenBlocks![index].fragenBlocks![i] = new Fragenblock(data, parent.path, HZBA_MODE.MODAL);
    //
    //   console.log("setDataFromCallback afterwards", fragenBlock);
    //   console.log("setDataFromCallback afterwards", store.state.currentHzba.modalHzba)
    // }

    const getNestedBreadcrumbTitle = (path: string) => {
      console.log("PATHH :    ",path )
      return path && currentBa.value && getNestedBreadcrumbTitles(currentBa.value, path);
    }

    return {
      fragenblock,
      currentBa,
      t,
      getNestedBreadcrumbTitle,
      getFrages,
      cancel,
      deleteInstance,
      saveInstance,
      duplicateInstance,
      openHzbaModal,
    };
  },
});
</script>