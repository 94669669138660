export const environment = {
  production: true,
  message: 'Building for Movinglayers DEV environment',
  SERVER_URL: 'https://survey-dev.movinglayers.eu',
  SERVER_PORT: '443',
  BACKEND_URL: 'https://survey-dev-be.movinglayers.eu',
  BACKEND_PORT: '443',
  SOCKET_PORT: '443',
  OTA_VERSION_URL: 'https://survey-dev-ota.movinglayers.eu/version',
  ENVIRONMENT: "dev",
  BUILD_VERSION: 'v0.1.12-dev.0' //Replaced by CI to something like v0.0.1
}

